export const deepmoveIcon = require('../assests/deepMoveIcon.png')
export const mobileIcon = require('../assests/mobileIcon.png')
export const backGroundIcon = require('../assests/backGroundIcon.png')
export const mobileSecondIcon = require('../assests/mobileSecondIcon.png')

export const appleButtonIcon = require('../assests/appleButtonIcon.png')
export const googleButtonIcon = require('../assests/appleButtonIcon.png')
export const ratingIcon = require('../assests/ratingIcon.png')
export const ovalIcon = require('../assests/Ovalicon.png')
export const landingIcon = require('../assests/landingIcon.png')

export const runningIcon = require('../assests/runningIcon.png')
export const mmaIcon = require('../assests/mmaIcon.png')
export const nutritionIcon = require('../assests/nutritionIcon.png')
export const sleepIcon = require('../assests/sleepIcon.png')

export const boxingIcon = require('../assests/boxingIcon.png')
export const cardioIcon = require('../assests/cardioIcon.png')
export const hiitIcon = require('../assests/hiitIcon.png')
export const strengthIcon = require('../assests/strengthIcon.png')
export const yogaIcon = require('../assests/yogaIcon.png')
export const weighsIcon = require('../assests/weighsIcon.png')
export const breathWorkIcon = require('../assests/breathWorkIcon.png')
export const pilatesIcon = require('../assests/pilatesIcon.png')
export const handBalancingIcon = require('../assests/handBalancingIcon.png')
export const restorationIcon = require('../assests/restorationIcon.png')

export const videoIcon = require('../assests/videoIcon.png')

export const paymentIcon = require('../assests/paymentIcon.png')
export const robustImage = require('../assests/Robust.jpg')
export const robustEvent1 = require('../assests/robustEvent1.png')
export const robustEvent2 = require('../assests/robustEvent2.png')
export const robustEvent3 = require('../assests/robustEvent3.png')
export const inStudioIcon = require('../assests/inStudioIcon.png')
export const onlineIcon = require('../assests/onlineIcon.png')
export const onDemandIcon = require('../assests/onDemandIcon.png')

export const processIcon = require('../assests/processIcon.png')
export const takeClassesIcon = require('../assests/takeClassesIcon.png')

export const dashboardIcon = require('../assests/dashboardIcon.png')
export const rewindIcon = require('../assests/rewindIcon.png')
export const activityIcon = require('../assests/activityIcon.png')
export const creditCardIcon = require('../assests/creditCardIcon.png')
export const feedbackIcon = require('../assests/feedbackIcon.png')
export const teamIcon = require('../assests/teamIcon.png')

export const notificationIcon = require('../assests/notificationIcon.png')
export const chatFeatureIcon = require('../assests/chatFeatureIcon.png')
export const directMessageIcon = require('../assests/directMessageIcon.png')
export const createClassIcon = require('../assests/CreateClassIcon.png')
export const locationSearchIcon = require('../assests/locationSearchIcon.png')
export const makeFriendsIcon = require('../assests/makeFriendsIcon.png')
export const messageBlueIcon = require('../assests/messageBlueIcon.png')
export const createGroupIcon = require('../assests/createGroupIcon.png')
export const searchLocationIcon = require('../assests/searchLocationIcon.png')
export const locateEventIcon = require('../assests/locateEventIcon.png')
export const locateIcon = require('../assests/locateIcon.png')
export const connectIcon = require('../assests/connectIcon.png')
export const playIcon = require('../assests/playIcon.png')
export const calenderIcon = require('../assests/calenderIcon.png')
export const reviewsIcon = require('../assests/reviewsIcon.png')
export const searchYellowIcon = require('../assests/searchYellowIcon.png')
export const connectClients = require('../assests/connectClients.png')
export const blueMessageIcon = require('../assests/blueMessageIcon.png')
export const bgSubLetter = require('../assests/bgSubLetter.png')
export const benefitRightImage = require('../assests/benefitRightImage.png');
export const marketPlaceIcon = require('../assests/marketPlaceIcon.png');


export const profileImageThreeIcon = require('../assests/ProfileImageThreeIcon.png');
export const profileImageOneIcon = require('../assests/ProfileImageOneIcon.png');
export const profileImageTwoIcon = require('../assests/ProfileImageTwoIcon.png');













