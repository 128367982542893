export const lightBrown = "#f3efe9"
export const lightBlue = "#f3f9fd"
export const whiteColor = '#ffffff'
export const lightGray = '#E4E4E7'
export const blackColor = "#12141D"
export const darkGray = "#667085"
export const darkBlue = "#344054"
export const lightWhite = "#f8faf9"
export const skyColor = "#eef4f7"
export const lightYellow = "#fffaea"
export const lightPink = "#f5f3ef"
export const lightGreen = "#f0f3ee"






