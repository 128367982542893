import * as React from "react"

const FooterPattern = (props) => (
  <svg
    width={props.width || 337}
    height={props.height || 337}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.2} fill="#fff">
      <path d="M47.684 121.202V70.508l22.884-22.884h56.506L109.698 65v44.641H65.057L47.68 127.017v-5.814l.004-.001Zm4.817-48.7v42.89l10.563-10.563h41.819V63.01l10.563-10.563h-42.89l-20.059 20.06.004-.004Z" />
      <path d="M61.32 107.563V78.007L78.069 61.26h35.366l-3.741 3.737v44.641h-44.65l-3.728 3.728v-5.801l.006-.002ZM66.139 80v24.822h38.75v-38.75H80.066L66.138 80Z" />
      <path d="M74.955 107.231V85.509l10.612-10.612h24.125v34.744H74.949v-2.408l.006-.002Zm4.817-19.725v17.321h25.111v-25.11H87.561l-7.79 7.789Z" />
      <path d="M88.594 101.512v-8.498l4.482-4.477h9.497l4.476 4.477v9.497l-4.476 4.476h-9.497l-4.482-4.476v-.999Zm4.817-6.505v5.511l1.654 1.659h5.512l1.658-1.659v-5.511l-1.658-1.655h-5.512l-1.654 1.655Z" />
      <path d="M64.06 104.826h45.638v25.697l2.657 2.658-2.657 2.657v65.525l26.946 26.94-4.066 4.066h-69.51l-29.014-29.014v-69.52l29.014-29.014h.999l-.006.005Zm40.821 4.817H65.054L38.86 135.838v65.525l26.195 26.195h64.03l-24.202-24.203v-69.52l.659-.659-.659-.659-.001-22.874Z" />
      <path d="M71.56 118.461h38.137v12.064l2.657 2.657-2.657 2.657v65.525l17.376 17.377H70.567l-22.884-22.885v-54.511l22.884-22.884h1-.006Zm33.321 4.817H72.555l-20.06 20.06v50.521l20.06 20.06h42.889l-10.563-10.567v-69.51l.659-.659-.659-.659v-9.246Z" />
      <path d="M79.064 132.096h34.373l-3.74 3.737v65.534l3.727 3.728c-11.788 0-23.569.004-35.357 0L61.32 188.348v-39.506l16.747-16.747h.994l.003.001Zm25.82 4.817H80.061l-13.928 13.924v35.515l13.928 13.925h24.823v-63.364Z" />
      <path d="M86.568 145.74h23.128v45.727H85.571l-10.612-10.612v-24.501l10.612-10.612h.999l-.002-.002Zm18.315 4.817H87.562l-7.79 7.789v20.517l7.79 7.789h17.321v-36.099.004Z" />
      <path d="M94.068 159.372h8.498l4.477 4.476v9.497l-4.477 4.483h-9.497l-4.482-4.483v-9.497l4.482-4.476h1Zm6.505 4.817h-5.511l-1.655 1.658v5.508l1.655 1.658h5.511l1.659-1.658v-5.508l-1.659-1.658ZM104.885 273.194v-45.637h25.697l2.657-2.657 2.658 2.657h65.525l26.94-26.946 4.066 4.065v69.511l-29.014 29.014h-69.52l-29.014-29.014v-.999l.005.006Zm4.817-40.82V272.2l26.195 26.195h65.525l26.194-26.195v-64.029l-24.202 24.202h-69.52l-.659-.659-.659.659-22.874.001Z" />
      <path d="M118.519 265.693v-38.136h12.064l2.657-2.657 2.657 2.657h65.525l17.373-17.377v56.507l-22.885 22.884h-54.511l-22.884-22.884v-.999l.004.005Zm4.817-33.32v32.326l20.06 20.06h50.521l20.06-20.06V221.81l-10.563 10.563h-69.52l-.66-.659-.659.659h-9.239Z" />
      <path d="M132.152 258.188v-34.373l3.737 3.741h65.525l3.737-3.741v35.366l-16.747 16.747c-13.166.004-26.34.004-39.506 0l-16.747-16.747v-.993h.001Zm4.817-25.819v24.822l13.924 13.928h35.515l13.925-13.928v-24.822h-63.364Z" />
      <path d="M145.797 250.684v-23.128h45.727v24.125l-10.612 10.612h-24.501l-10.612-10.612v-.999l-.002.002Zm4.817-18.316v17.322l7.79 7.789h20.516l7.789-7.789v-17.322h-36.098.003Z" />
      <path d="M159.43 243.185v-8.497l4.483-4.477h9.497l4.482 4.477v9.496l-4.482 4.483h-9.497l-4.483-4.483v-.999Zm4.817-6.504v5.511l1.659 1.655h5.507l1.659-1.655v-5.511l-1.659-1.659h-5.507l-1.659 1.659ZM205.729 104.826h68.521l29.019 29.019v69.511l-29.019 29.013h-46.634v-25.697l-2.657-2.657 2.657-2.657v-65.525l-26.946-26.941 4.065-4.065.994-.001Zm66.525 4.817h-64.029l24.202 24.203v69.52l-.659.659.659.659v22.878h39.827l26.194-26.194v-65.525l-26.194-26.2Z" />
      <path d="M216.052 118.461h50.694l22.885 22.884v54.511l-22.885 22.885h-39.135v-12.064l-2.657-2.657 2.657-2.657v-65.525l-17.376-17.373 5.817-.004Zm48.699 4.817h-42.889l10.563 10.563v69.52l-.659.659.659.66v9.239h32.326l20.06-20.06v-50.521l-20.06-20.06Z" />
      <path d="M229.691 132.095h29.556l16.747 16.747v39.506l-16.747 16.747H223.89l3.728-3.728v-65.534l-3.741-3.737h5.814v-.001Zm27.564 4.817h-24.822v63.362h24.822l13.928-13.925v-35.514l-13.928-13.923Z" />
      <path d="M230.022 145.739h21.722l10.612 10.612v24.501l-10.612 10.611h-24.125v-45.726h2.407l-.004.002Zm19.726 4.817h-17.322v36.099h17.322l7.789-7.79v-20.516l-7.789-7.789v-.004Z" />
      <path d="M235.744 159.372h8.498l4.482 4.476v9.497l-4.477 4.483h-9.496l-4.477-4.483v-9.497l4.477-4.476h.999-.006Zm6.505 4.817h-5.512l-1.658 1.658v5.508l1.658 1.658h5.512l1.655-1.658v-5.508l-1.655-1.658ZM104.885 131.523v-68.52l29.014-29.014h69.52l29.013 29.014v46.634h-25.697l-2.657 2.657-2.657-2.657h-65.525l-26.94 26.946-4.066-4.066-.005-.994Zm4.817-66.525v64.03l24.202-24.202h69.521l.659.659.659-.659h22.878V64.999l-26.194-26.195h-65.525l-26.2 26.194Z" />
      <path d="M118.518 121.201V70.507l22.884-22.884h54.512l22.884 22.884v39.136h-12.064l-2.657 2.657-2.657-2.657h-65.525l-17.373 17.376-.004-5.818Zm4.817-48.699v42.89l10.563-10.564h69.52l.66.66.659-.66h9.239V72.502l-20.06-20.06h-50.521l-20.06 20.06Z" />
      <path d="M132.153 107.563V78.007L148.9 61.26h39.506l16.747 16.747v35.357l-3.728-3.727h-65.544l-3.728 3.727v-5.801ZM136.97 80v24.822h63.362V80L186.407 66.07h-35.514L136.97 80Z" />
      <path d="M145.796 107.231V85.509l10.612-10.612h24.501l10.612 10.612v24.126h-45.727v-2.408l.002.004Zm4.817-19.725v17.321h36.099V87.506l-7.789-7.79h-20.517l-7.789 7.79h-.004Z" />
      <path d="M159.43 101.512v-8.498l4.483-4.477h9.497l4.482 4.477v9.497l-4.482 4.476h-9.497l-4.483-4.476v-.999Zm4.817-6.505v5.511l1.659 1.659h5.507l1.659-1.659v-5.511l-1.659-1.655h-5.507l-1.659 1.655Z" />
      <path d="M104.885 202.355v-68.52l29.014-29.014h69.52l29.013 29.014v69.52l-29.013 29.014h-69.52l-29.014-29.014v-1Zm4.817-66.525v65.525l26.195 26.195h65.525l26.194-26.195V135.83l-26.194-26.195h-65.525l-26.195 26.195Z" />
      <path d="M118.518 194.857v-53.512l22.884-22.884h54.512l22.884 22.884v54.511l-22.884 22.885c-18.171.003-36.338.003-54.512 0l-22.884-22.885v-.999Zm4.817-51.517v50.521l20.06 20.06h50.521l20.06-20.06V143.34l-20.06-20.06h-50.521l-20.06 20.06Z" />
      <path d="M132.153 187.351v-38.506l16.747-16.747h39.506l16.747 16.747c.004 13.166.004 26.339 0 39.505l-16.747 16.747H148.9l-16.747-16.747v-.999Zm4.817-36.514v35.515l13.925 13.925h35.515l13.924-13.925v-35.515l-13.924-13.924h-35.515l-13.925 13.924Z" />
      <path d="M145.797 179.851v-23.507l10.612-10.612h24.501l10.612 10.612v24.501l-10.612 10.612h-24.501l-10.612-10.612v-.994Zm4.817-21.509v20.512l7.79 7.793h20.516l7.789-7.793v-20.512l-7.789-7.79h-20.516l-7.79 7.79Z" />
      <path d="M159.43 172.352v-8.498l4.483-4.482h9.497l4.482 4.482v9.497l-4.482 4.477h-9.497l-4.483-4.477v-.999Zm4.817-6.505v5.508l1.659 1.658h5.507l1.659-1.658v-5.508l-1.659-1.658h-5.507l-1.659 1.658ZM216.052 47.624h50.694L289.63 70.51v56.506l-17.376-17.376h-44.642V64.998L210.236 47.62h5.814l.002.003Zm48.699 4.817h-42.89l10.564 10.564v41.819h41.818l10.564 10.563v-42.89l-20.06-20.06.004.005Z" />
      <path d="M229.69 61.265h29.556l16.747 16.747v35.357l-3.727-3.727h-44.651V64.991l-3.728-3.728h5.801l.002.002Zm27.564 4.817h-24.822v38.75h38.75V80.01l-13.928-13.928Z" />
      <path d="M230.022 74.895h21.721l10.612 10.612v24.125h-34.743V74.889h2.408l.002.006Zm19.725 4.817h-17.322v25.111h25.111V87.501l-7.789-7.789Z" />
      <path d="M235.743 88.534h8.498l4.477 4.477v9.497l-4.477 4.477h-9.497l-4.477-4.477v-9.497l4.477-4.477h.999Zm6.505 4.817h-5.512l-1.658 1.655v5.512l1.658 1.658h5.512l1.655-1.658v-5.512l-1.655-1.654ZM52.499 221.807v42.889l20.06 20.06h42.889l-10.563-10.563v-41.819h-41.82L52.5 221.807Zm-4.817 43.883v-55.507l17.376 17.376h44.641v44.642l17.377 17.376H70.569l-22.884-22.884v-.999l-.003-.004Z" />
      <path d="M66.133 232.374v24.822l13.928 13.928h24.823v-38.75H66.133Zm-4.817 25.819v-34.36l3.727 3.728h44.651v44.641l3.741 3.737H78.069l-16.747-16.747v-.994l-.006-.005Z" />
      <path d="M79.776 232.374v17.322l7.79 7.789h17.321v-25.111H79.776Zm-4.817 18.315v-23.128h34.743v34.743H85.576l-10.611-10.611v-.999l-.006-.005Z" />
      <path d="M93.41 236.681v5.512l1.655 1.654h5.512l1.658-1.654v-5.512l-1.658-1.658h-5.512l-1.654 1.658Zm-4.816 6.505v-8.498l4.482-4.477h9.497l4.476 4.477v9.497l-4.476 4.477h-9.497l-4.482-4.477v-.999ZM221.863 284.755h42.889l20.06-20.06v-42.889l-10.563 10.563H232.43v41.819l-10.567 10.567Zm43.883 4.817h-55.507l17.377-17.376v-44.642h44.641l17.376-17.376v56.506l-22.884 22.885h-.999l-.004.003Z" />
      <path d="M232.431 271.12h24.822l13.928-13.928V232.37h-38.75v38.75Zm25.819 4.817h-34.373l3.741-3.737v-44.641h44.65l3.728-3.731v35.363l-16.747 16.747-.999-.001Z" />
      <path d="M232.431 257.477h17.322l7.789-7.79v-17.321h-25.111v25.111Zm18.316 4.817h-23.128v-34.743h34.743v24.125l-10.612 10.612h-.999l-.004.006Z" />
      <path d="M236.737 243.844h5.512l1.654-1.654v-5.512l-1.654-1.658h-5.512l-1.658 1.658v5.512l1.658 1.654Zm6.505 4.817h-8.498l-4.477-4.477v-9.496l4.477-4.477h9.497l4.477 4.477v9.496l-4.477 4.477h-.999Z" />
    </g>
  </svg>
)

export default FooterPattern
