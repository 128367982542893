import {
  Image,
  HStack,
  Text,
  VStack,
  Stack,
  Container,
} from "native-base";
import { lightBlue } from "../constants/Colors";
import {
  dashboardIcon,
  rewindIcon,
  activityIcon,
  creditCardIcon,
} from "../Images/CommonImages";
export const DashBoardComponent = (props) => {
  return (
    <Stack
      flex={1}
      direction={["column", "column", "column", "row"]}

      paddingTop={"69px"}
      justifyContent={'space-evenly'}
      bg={lightBlue}
    >

      <Image
        source={{ uri: dashboardIcon }}
        width={"722.59px"}
        height={["326.56px", "426.56px"]}
        resizeMode={"contain"}
        // flex={0.4}
        alignSelf={["center", "center", "center", 'flex-end']}
      />

      <Stack alignItems={["center", "center", "center", "flex-start"]} justifyContent={['center']} >

        <Text flexWrap={"wrap"} paddingLeft={[20, 20, 20, 0]} fontSize={44} fontWeight={"700"}>
          Personal Dashboard
        </Text>

        <HStack>
          <Image source={{ uri: rewindIcon }} width={"24px"} height={"24px"} />
          <Text
            pl={"14px"}
            color={"#344054"}
            flexWrap={"wrap"}
            fontSize={20}
            fontWeight={"500"}
          >
            Track Your Progress
          </Text>
        </HStack>
        <Text color={"#98A2B3"} paddingTop={"4px"} paddingLeft={"40px"}>
          Weekly, Monthly, Total
        </Text>

        <HStack paddingTop={"16px"} alignItems={"center"}>
          <Image
            source={{ uri: activityIcon }}
            width={"24px"}
            height={"24px"}
          />
          <Text
            pl={"14px"}
            color={"#344054"}
            flexWrap={"wrap"}
            fontSize={20}
            fontWeight={"500"}
          >
            Audience Statistics
          </Text>
        </HStack>
        <Text color={"#98A2B3"} paddingTop={"4px"} paddingLeft={"40px"}>
          See what your students need
        </Text>

        <HStack paddingTop={"16px"} alignItems={"center"}>
          <Image
            source={{ uri: creditCardIcon }}
            width={"24px"}
            height={"24px"}
          />
          <Text
            pl={"14px"}
            color={"#344054"}
            flexWrap={"wrap"}
            fontSize={20}
            fontWeight={"500"}
          >
            Transaction Statistics
          </Text>
        </HStack>
        <Text color={"#98A2B3"} paddingTop={"4px"} paddingLeft={"40px"}>
          Control your payments in one place
        </Text>
      </Stack>
    </Stack>
  );
};
