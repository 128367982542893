import { Box, Text, VStack, Stack, Pressable } from "native-base";
import AppleDownloadSvg from "../assests/svg/AppleDownloadSvg";
import GoogleDownloadSvg from "../assests/svg/GoogleDownloadSvg";
import { darkGray, lightBlue } from "../constants/Colors";
import CONSTANTS from "../helper/Constants";
import { openUrl } from "../helper/Utils";
export const Download = (props) => {
  return (
    <Box
      width={"100%"}
      backgroundColor={
        props.backgroundColor ? props.backgroundColor : lightBlue
      }
      height={[378, 378, 378, 278]}
      justifyContent="center"
      p={[5, 0, 0, 0]}
    >
      <Stack
        justifyContent={"center"}
        alignItems="center"
        flex={1}
        direction={["column", "column", "column", "row"]}
      >
        <VStack
          maxWidth={433}
          flex={0.5}
          justifyContent="center"
          alignSelf={"center"}
        >
          <Text fontWeight={"700"} fontSize={32} lineHeight={46}>
            Download our mobile app ⚡️
          </Text>
          <Text
            pt={'20px'}
            fontWeight={"400"}
            fontSize={20}
            lineHeight={36}
            color={darkGray}
          >
            We are prioritizing total wellness for 2022 and beyond.
          </Text>
        </VStack>
        <Stack
          flex={0.5}
          justifyContent="flex-end"
          alignSelf={"center"}

          direction={["column", "column", "row", "row"]}
        >
          <Pressable
            onPress={() => {
              openUrl(CONSTANTS.VALUES.APPSTORE_URL);
            }}
          >
            <AppleDownloadSvg />
          </Pressable>
          <Stack padding={[2]} />
          {/* <Pressable
            onPress={() => {
              openUrl(CONSTANTS.VALUES.PLAY_STORE_URL);
            }}
          >
            <GoogleDownloadSvg />
          </Pressable> */}
        </Stack>
      </Stack>
    </Box>
  );
};
