import { Box, Image, Text, VStack, Container } from "native-base";
import { lightBlue } from "../constants/Colors";
import { processIcon } from "../Images/CommonImages";
export const EarningStepComponent = (props) => {
  return (
    <VStack
      flex={1}
      paddingBottom={[10, 10, 10, "108px"]}
      alignItems={"center"}
      paddingTop={"69px"}
      bg={lightBlue}
    >
      <Container alignItems={"center"} flex={1}>
        <Text flexWrap={"wrap"} fontSize={44} fontWeight={"700"} width={['100%','100%', '80%','80%', '70%']}>
          Build Your Network and Your Wellness Journey with deepmove
        </Text>
        <Text
          color={"#667085"}
          pt={"16px"}
          flexWrap={"wrap"}
          fontSize={20}
          fontWeight={"400"}
          textAlign={'justify'}
        >
          as an instructor, student, or studio
        </Text>
      </Container>
      <Box paddingTop={[10, 10, 10, "146px"]} />
      <Image
        source={{ uri: processIcon }}
        width={"1196.69px"}
        height={"191px"}
        resizeMode={"contain"}
      />
    </VStack>
  );
};
