import { Box, Text, VStack, Stack, Image, HStack, Center } from "native-base";
import { lightWhite, skyColor } from "../constants/Colors";
import {
  blueMessageIcon,
  connectClients,
} from "../Images/CommonImages";
export const ConnectClients = (props) => {
  return (
    <Box
      width={"100%"}
      backgroundColor={
        props.backgroundColor ? props.backgroundColor : lightWhite
      }
    //  alignItems={"center"}

    >
      <Stack
        justifyContent={"space-evenly"}
        alignItems="center"
        pt={"154px"}
        flex={1}
        direction={["column", "column", "column", "row"]}
      >
        <Image
          source={{ uri: connectClients }}
          width={"567px"}
          height={"567.99px"}
          resizeMode="contain"
        />
        <VStack flex={0.5} px={[10, 10, 10, 0]} alignItems={['center', 'center', 'center', 'flex-start']}>
          <Text fontSize={44} fontWeight="700">
            Connect with Clients
          </Text>
          <Text>
            Engage on a social level, not through outdated business platforms.
          </Text>
          <HStack bg={skyColor} mt={"24px"} p={"16px"} borderRadius={"16px"}>
            <Stack flex={0.1}>
              <Image
                source={{
                  uri: props.bottomBarImage
                    ? props.bottomBarImage
                    : blueMessageIcon,
                }}
                width={"56px"}
                height={"56px"}
                resizeMode="contain"
              />
            </Stack>
            <VStack flex={0.7} paddingLeft={"16px"}>
              <Text fontWeight={"500"}>
                {props.bottomBarHeaderText
                  ? props.bottomBarHeaderText
                  : "Direct Messages"}
              </Text>
              <Text color={"#667085"} fontWeight={"400"}>
                {props.bottomBarSimpleText
                  ? props.bottomBarSimpleText
                  : "No more missed emails or archaic means of communicating. Meet your clients where they are in a format familiar to them."}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Stack>
    </Box>
  );
};
