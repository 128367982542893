import {
  ScrollView,
  Box,
  NativeBaseProvider,
  Container,
} from "native-base";
import { Download } from "../components/Download";
import { FeedBackComponent } from "../components/FeedBackComponent";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { LocateComponent } from "../components/LocateComponent";
import { MakeFriendComponent } from "../components/MakeFriendComponent";
import { ManageAppComponent } from "../components/ManageAppComponent";
import { ModalitiesComponent } from "../components/ModalitiesComponent";
import { SubscribeNewsletters } from "../components/SubscribeNewsletters";
import { lightBrown, lightWhite } from "../constants/Colors";
import { locationSearchIcon } from "../Images/CommonImages";

const Students = () => {
  return (
    <NativeBaseProvider>
      <ScrollView bg={lightWhite}>
        <Box bg={lightWhite} flex={1}>
          <Header
            headerText={"One profile, one login, all friends, teachers, and studios."}
            simpleText={'We typically gather our wellness practices from a variety of sources. Rather than manage these sources independently, merge them with deepmove. Find your friends and your teachers and know where the best classes for your needs are, always.'}
          />
          <Container marginTop={20} />
          <ModalitiesComponent title={"Visit Classes"} noGroupClasses />
          <MakeFriendComponent />
          <FeedBackComponent
            Image={locationSearchIcon}
            headerText={"Search by Location, Modality, Instructor"}
            simpleText={
              "Find the Best Instructors for yourself with advanced search filters"
            }
          />
          <LocateComponent />
          <ManageAppComponent />

          <SubscribeNewsletters />
          <Download backgroundColor={lightBrown} />
          <Footer />
        </Box>
      </ScrollView>
    </NativeBaseProvider>
  );
};

export default Students;
