import { Image, Text, Stack, Container } from "native-base";
import { lightBrown } from "../constants/Colors";
import { onDemandIcon } from "../Images/CommonImages";
export const DemandVideo = (props) => {
  return (
    <Stack
      flex={1}
      direction={["column", "column", "column", "row"]}
      alignItems={"center"}
      paddingTop={[10, 10, 10, "154px"]}
      bg={lightBrown}
    >
      <Image
        source={{ uri: onDemandIcon }}
        width={"667px"}
        height={"367.99px"}
        resizeMode={"contain"}
      />
      <Container
        pb={[10, 10, 10, 0]}
        pr={[0, 0, 0, "154px"]}
        pt={[10, 10, 10, "0"]}
        flex={1}
        alignItems={'flex-start'}
      >
        <Text flexWrap={"wrap"} fontSize={44} fontWeight={"700"}>On-Demand Videos{" "}</Text>
        <Text pt={"16px"} flexWrap={"wrap"} fontSize={20} fontWeight={"400"}>
          As an instructor, teach your classes live, then add them to on-demand for later, or list your pre-recorded classes in your video library, making them available for purchase and repeat viewings. As a student, browse your favorite instructors’ video libraries and buy only the classes that interest you most. No need for platform-wide subscriptions exist here.
        </Text>
      </Container>
    </Stack>
  );
};
