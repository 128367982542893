import { Box, Image, Text, VStack, Stack } from "native-base";
import { createClassIcon } from "../Images/CommonImages";
export const CreateClassComponent = (props) => {
  return (
    <Box flex={1} width={"100%"} pl={[0, 0, 0, "0px"]} bg={"#f8faf9"}>
      <Stack
        marginTop={"100px"}
        direction={["column", "column", "column", "row"]}
        justifyContent="space-evenly"
        alignItems={["center"]}
        padding={20}
      >
        <VStack
          flex={1}
          alignItems="center"
          justifyContent={"center"}
          padding={[0, 0, 20, 20]}
        >
          <Text fontSize={44} lineHeight={50} fontWeight={"700"}>
            Create classes, promos, and events
          </Text>
          <Text
            marginTop={"22px"}
            fontSize={20}
            fontWeight={"400"}
            color={"#344054"}
          >
            Post, host, and feature classes (virtual, in-person, and on-demand),
            promotions, and events.
          </Text>
        </VStack>

        <Image
          source={{ uri: createClassIcon }}
          width={"599.55px"}
          height={["315.34px", "315.34px", "315.34px", "615.34px"]}
          resizeMode="contain"
        />
      </Stack>
    </Box>
  );
};
