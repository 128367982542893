import * as React from 'react'
import {
  Image,
  HStack,
  Pressable,
  Text,
  VStack,
  Stack,
  Hidden,

  Select,
  Button,
  CheckIcon,
  HamburgerIcon,
  ThreeDotsIcon,
  Box,
  Divider,
  Container,
} from "native-base";
import AppStoreButton from "../assests/AppStoreButton";
import PlayStoreButton from "../assests/svg/PlayStoreButton";
import {
  landingIcon,
  backGroundIcon,
  ratingIcon,
  ovalIcon,
} from "../Images/CommonImages";
import { useNavigate } from "react-router-dom";
import DeepmoveIcon from "../assests/svg/deepMoveIcon";
import { blackColor, whiteColor } from "../constants/Colors";
import { Link } from "react-router-dom";
import { openUrl } from "../helper/Utils";
import CONSTANTS from "../helper/Constants";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import VerticalThreeDotSvg from '../assests/svg/VerticalThreeDotSvg';

export const Header = (props) => {
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const [position, setPosition] = React.useState("Right");

  const MenuBar = () => {
    return (
      <Container marginTop={20} px={36} width={"100%"}>
        <Sidebar backgroundColor='#fffff'>
          <Menu>

            <Pressable
              marginTop={[2, 2, 2, 0]}
              onPress={() => {
                navigate("/Students");
              }}
            >
              <Text fontSize={16} fontWeight={"500"}>
                Students
              </Text>
            </Pressable>
            <Pressable
              marginTop={[2, 2, 2, 0]}
              onPress={() => {
                navigate("/Teachers");
              }}
            >
              <Text fontSize={16} fontWeight={"500"}>
                Teachers
              </Text>
            </Pressable>
            <Pressable
              marginTop={[2, 2, 2, 0]}
              onPress={() => {
                navigate("/Studios");
              }}
            >
              <Text fontSize={16} fontWeight={"500"}>
                Studios
              </Text>
            </Pressable>
            {/* <Pressable
              marginTop={[2, 2, 2, 0]}
              onPress={() => {
                // navigate("/Pages");
              }}
            >
              <Text fontSize={16} fontWeight={"500"}>
                Pages
              </Text>
            </Pressable> */}
            <Pressable marginTop={[2, 2, 2, 0]}>
              <Link
                style={{
                  fontSize: 16,
                  fontWeight: "500",
                  color: blackColor,
                  textDecoration: "none",
                }}
                to="#"
                onClick={(e) => {
                  window.location.href = "mailto:hello@deepmove.com";
                  e.preventDefault();
                }}
              >
                Contact
              </Link>
            </Pressable>
            <Stack flex={0.2} flexDirection={["column", "column", "column", "row"]}>
              <Pressable
                marginTop={[2, 2, 2, 0]}
                width={"130px"}
                height={"48px"}
                backgroundColor="#5589A8"
                borderRadius={"6px"}
                alignItems={"center"}
                justifyContent={"center"}
                onPress={() => { openUrl(CONSTANTS.VALUES.LOGIN_URL, '_self') }}
              >
                <Text fontSize={"18px"} fontWeight={"500"} color={"#FFFFFF"}>
                  Login
                </Text>
              </Pressable>
              {!props.LandingScreen && (
                <Pressable
                  marginTop={[2, 2, 2, 0]}
                  marginLeft={[0, 0, 0, 5]}
                  width={"130px"}
                  height={"48px"}
                  borderColor={[blackColor, blackColor, blackColor, whiteColor]}
                  borderWidth={"1px"}
                  borderRadius={"6px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    fontSize={"18px"}
                    fontWeight={"500"}
                    color={[blackColor, blackColor, blackColor, whiteColor]}
                  >
                    Sign Up
                  </Text>
                </Pressable>
              )}
            </Stack>
            {/* <MenuItem> Documentation </MenuItem>
          <MenuItem> Calendar </MenuItem> */}
          </Menu>
        </Sidebar>
      </Container>)
  }
  return (
    <Stack
      direction={["column", "column", "column", "column"]}
      flex={1}
      backgroundColor={
        props.backGroundColor ? props.backGroundColor : "#f8faf9"
      }
    // px={38}
    // pt={10}
    >

      <Stack
        pr={"185px"}
        zIndex={99}
        direction={["column", "column", "column", "row"]}
        alignItems={["flex-start", "flex-start", "flex-start", "center"]}
        justifyContent={"space-between"}
        position={'absolute'}
        width={["100%", '100%']}
        top={10}
        px={36}
      >

        <Pressable
          onPress={() => {
            navigate("/");
          }}
        >
          <DeepmoveIcon />
        </Pressable>
        <Hidden only={["base", "sm", "md"]}>
          <Pressable
            marginTop={[2, 2, 2, 0]}
            onPress={() => {
              navigate("/Students");
            }}
          >
            <Text fontSize={16} fontWeight={"500"}>
              Students
            </Text>
          </Pressable>
          <Pressable
            marginTop={[2, 2, 2, 0]}
            onPress={() => {
              navigate("/Teachers");
            }}
          >
            <Text fontSize={16} fontWeight={"500"}>
              Teachers
            </Text>
          </Pressable>
          <Pressable
            marginTop={[2, 2, 2, 0]}
            onPress={() => {
              navigate("/Studios");
            }}
          >
            <Text fontSize={16} fontWeight={"500"}>
              Studios
            </Text>
          </Pressable>
          {/* <Pressable
            marginTop={[2, 2, 2, 0]}
            onPress={() => {
              // navigate("/Pages");
            }}
          >
            <Text fontSize={16} fontWeight={"500"}>
              Pages
            </Text>
          </Pressable> */}
          <Pressable marginTop={[2, 2, 2, 0]}>
            <Link
              style={{
                fontSize: 16,
                fontWeight: "500",
                color: blackColor,
                textDecoration: "none",
              }}
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:hello@deepmove.com";
                e.preventDefault();
              }}
            >
              Contact
            </Link>
          </Pressable>
          <Stack flex={0.2} flexDirection={["column", "column", "column", "row"]}>
            <Pressable
              marginTop={[2, 2, 2, 0]}
              width={"130px"}
              height={"48px"}
              backgroundColor="#5589A8"
              borderRadius={"6px"}
              alignItems={"center"}
              justifyContent={"center"}
              onPress={() => { openUrl(CONSTANTS.VALUES.LOGIN_URL, '_self') }}
            >
              <Text fontSize={"18px"} fontWeight={"500"} color={"#FFFFFF"}>
                Login
              </Text>
            </Pressable>
            {!props.LandingScreen && (
              <Pressable
                marginTop={[2, 2, 2, 0]}
                marginLeft={[0, 0, 0, 5]}
                width={"130px"}
                height={"48px"}
                borderColor={[blackColor, blackColor, blackColor, whiteColor]}
                borderWidth={"1px"}
                borderRadius={"6px"}
                alignItems={"center"}
                justifyContent={"center"}
                onPress={() => { openUrl(CONSTANTS.VALUES.LOGIN_URL, '_self') }}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"500"}
                  color={[blackColor, blackColor, blackColor, whiteColor]}
                >
                  Sign Up
                </Text>
              </Pressable>
            )}
          </Stack>
        </Hidden>
        <Hidden only={["lg", "xl"]}>
          <Pressable style={{ position: "absolute", right: 0 }} mt={1} onPress={() => setShowMenu(!showMenu)}>
            <VerticalThreeDotSvg />
          </Pressable>
        </Hidden>
      </Stack>
      <Hidden only={["lg", "xl"]}>
        {showMenu && MenuBar()}
      </Hidden>

      <Stack
        flexDirection={["column", "column", "column", "row"]}
        alignItems={["center"]}
        paddingTop={"80px"}
      >
        <VStack pr={36} pl={36} pt={"80px"} width={["100%", "100%", "100%", "50%"]}>
          <Text
            fontSize={68}
            lineHeight={80}
            fontWeight="500"
            color={"#344054"}
          >
            {props.headerText ? props.headerText : "Lorem ipsum sit dolar amet"}
          </Text>
          <Text
            paddingTop={"16px"}
            fontSize={20}
            lineHeight={32}
            fontWeight="400"
            color={"#344054"}
          >
            {props.simpleText ? props.simpleText : ""}
          </Text>
          <HStack paddingTop={"16px"}>
            <Pressable width={165.38} height={52} onPress={() => { openUrl(CONSTANTS.VALUES.APPSTORE_URL) }}>
              <AppStoreButton />
            </Pressable>
            {/* <Pressable marginLeft={13} width={165.38} height={52} onPress={() => { openUrl(CONSTANTS.VALUES.PLAY_STORE_URL) }}>
              <PlayStoreButton />
            </Pressable> */}
          </HStack>

          <Image
            source={{ uri: ratingIcon }}
            width={86}
            height={16}
            resizeMode="contain"
          />
          <Text fontSize={16} fontWeight={"400"}>
            It is so nice to access my teachers, the studios I go to, and my friends. I like how simple deepmove has made everything. I sign in, know what my recent stats are, and what classes are available. Super easy!
          </Text>
          <HStack paddingTop={"16px"} paddingBottom={"18px"}>
            <Image
              source={{ uri: ovalIcon }}
              width={"43px"}
              height={"43px"}
              resizeMode="contain"
              borderRadius={"100px"}
            />
            <VStack pl={"16px"}>
              <Text fontSize={16} lineHeight={20} fontWeight={"500"}>
                Naomi
              </Text>
              <Text fontSize={14} fontWeight={"400"}>
                Surfer & Student of Yoga
              </Text>
            </VStack>
          </HStack>
        </VStack>

        {!props.LandingScreen ? (

          < Image
            source={{ uri: backGroundIcon }}
            width={["395.62px", "395.62px", "795.62px", "50%"]}
            height={["434.1px", "434.1px", "734.1px", "100%"]}
            // resizeMode="stretch"
            // flex={1}

            alt="image"
            position={['', '', '', "absolute"]}
            top="0"
            right={0}
            resizeMode="stretch"
          />


        ) : (
          <Image
            source={{ uri: landingIcon }}
            width={["380px", "380px", "380px", "618px"]}
            height={["380px", "380px", "380px", "710px"]}
            resizeMode="contain"
          />
        )}
      </Stack>

    </Stack >
  );
};
