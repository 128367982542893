import * as React from "react"

const TwitterSvg = (props) => (
  <svg
    width={13}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.092 1.157a5.102 5.102 0 0 1-1.327.372c.466-.28.838-.721 1.001-1.257a5.046 5.046 0 0 1-1.467.559A2.32 2.32 0 0 0 8.623.109 2.285 2.285 0 0 0 6.342 2.39c0 .187.023.35.07.513A6.522 6.522 0 0 1 1.686.505c-.186.35-.302.722-.302 1.14 0 .792.395 1.49 1.024 1.91a2.694 2.694 0 0 1-1.048-.28v.023A2.3 2.3 0 0 0 3.2 5.556c-.187.047-.396.07-.606.07-.14 0-.303-.023-.442-.046.302.908 1.14 1.583 2.141 1.583a4.657 4.657 0 0 1-2.84.977c-.186 0-.372 0-.535-.023a6.478 6.478 0 0 0 3.515 1.024c4.214 0 6.518-3.491 6.518-6.518v-.302a4.18 4.18 0 0 0 1.14-1.164Z"
      fill="#fff"
    />
  </svg>
)

export default TwitterSvg
