import CONSTANTS from "./Constants";

class RestHelper {
  post = (params: Object) => {
    this.call(params, "POST");
  };
  put = (params: Object) => {
    this.call(params, "PUT");
  };

  get = (params: Object) => {
    this.call(params, "GET");
  };

  call = ({ url, body, cb, signal, errorCb }, method, contentType) => {
    if (method === "POST") {
    
    }
    const requestOptions = {
      signal,
      method,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };

    if (method !== "GET") {
      requestOptions.body =
        body &&
        (contentType && contentType.match("multipart")
          ? body
          : JSON.stringify(body));
    }

    fetch(CONSTANTS.VALUES.BASE_SERVER_URL + url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          let error = new Error(response.statusText);
          let description = `name: updateClass, api: ${url}, method::POST`;
          //logError(description, error, "Edit Class");
          return response.json();
        }
      })
      .then((responseJson) => {
        //console.log(url, 'responseJson', responseJson)
        if (responseJson && !responseJson.success && responseJson.message) {
          if (responseJson.message !== "Please Login To Continue") {
            alert(responseJson.message);
          } else if (responseJson.message == "Please Login To Continue") {
          }
        }
        cb(responseJson);
      })
      .catch((error) => {
        if (errorCb) errorCb(error);
        let description = `name: updateClass, api: ${url}, method::POST`;
        //logError(description, error, "Edit Class");
        console.log("error=", error);
      });
  };
}

export default new RestHelper();
