import {
  Box,
  Image,
  HStack,
  Text,
  VStack,
  Stack,
} from "native-base";
import MailSvg from "../assests/svg/MailSvg";
import MessageSvg from "../assests/svg/MessageSvg";
import NotificationSvg from "../assests/svg/NotificationSvg";
import { darkGray } from "../constants/Colors";
import {
  directMessageIcon,
  notificationIcon,
  chatFeatureIcon,
} from "../Images/CommonImages";
export const ManageAppComponent = (props) => {
  return (
    <Box flex={1} width={"100%"} pl={[0, 0, 0, "0px"]} bg={"#f8faf9"}>
      <Stack
        marginTop={"100px"}
        direction={["column", "column", "column", "column"]}
        justifyContent="space-between"
        alignItems={"center"}

      >
        <Stack
          marginTop={"100px"}
          direction={["column", "column", "column", "row"]}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <VStack alignItems={"center"}>
            <Image
              source={{ uri: directMessageIcon }}
              width={"395.53px"}
              height={"380.07px"}
              resizeMode="contain"
            />
            <HStack alignItems={"center"}>
              <Box
                width={"56px"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={100}
                height={"56px"}
                bg={"#ffffff"}
              >
                <MailSvg />
              </Box>
              <Text fontSize={22} fontWeight={"500"}>
                {" "}
                Direct Messages
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems={"center"}>
            <Image
              source={{ uri: notificationIcon }}
              width={"395.53px"}
              height={"380.07px"}
              resizeMode="contain"
            />
            <HStack alignItems={"center"}>
              <Box
                width={"56px"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={100}
                height={"56px"}
                bg={"#ffffff"}
              >
                <NotificationSvg />
              </Box>
              <Text fontSize={22} fontWeight={"500"}>
                {" "}
                Notifications
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems={"center"}>
            <Image
              source={{ uri: chatFeatureIcon }}
              width={"395.53px"}
              height={"380.07px"}
              resizeMode="contain"
            />
            <HStack alignItems={"center"}>
              <Box
                width={"56px"}
                alignItems={"center"}
                justifyContent={"center"}
                borderRadius={100}
                height={"56px"}
                bg={"#ffffff"}
              >
                <MessageSvg />
              </Box>
              <Text fontSize={22} fontWeight={"500"}>
                {" "}
                Chat Features
              </Text>
            </HStack>
          </VStack>
        </Stack>
        <Text
          color={darkGray}
          marginX={[20, 20, 20, 0]}
          fontSize={22}
          fontWeight={"400"}
          marginTop={[20, 20, 20, 20]}
          marginBottom={[20, 20, 20, 20]}
        >
          Utilize and manage multiple communication options in one place.
        </Text>
      </Stack>
    </Box>
  );
};
