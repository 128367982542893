import { Box, Text, VStack, Input, Button, Image } from "native-base";
import { useState } from "react";
import RestHelper from "../helper/RestHelper";
import { bgSubLetter } from "../Images/CommonImages";
import MailchimpSubscribe from "react-mailchimp-subscribe"
var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const SubscribeNewsletters = (props) => {

  const url = "https://deepmove.us5.list-manage.com/subscribe/post?u=2e01a37dc788b0bac4d621f77&id=6f8753aeb0";
  const [email, setEmail] = useState('');

  return (
    <Box width={"100%"} justifyContent="center">
      <Image
        source={bgSubLetter}
        alt="image"
        position="absolute"
        top="0"
        width="100%"
        height={"100%"}
        resizeMode="stretch"
      />

      <VStack
        maxWidth={601}
        flex={1}
        justifyContent="center"
        alignSelf={"center"}
        alignItems="center"
      >
        <Text
          fontWeight={"700"}
          fontSize={"44px"}
          lineHeight={"63.05px"}
          color={"#344054"}
          textAlign="center"
          marginTop={"75px"}
        >
          Subscribe to our Newsletter
        </Text>
        <Text
          fontWeight={"400"}
          fontSize={20}
          marginTop={8}
          lineHeight={36}
          textAlign="center"
          color={"#667085"}
        >
          Be the first to learn of new developments. We’d love to stay in touch.
        </Text>

        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => {
            return (
              <>
                <Input
                  type="text"
                  w="84%"
                  backgroundColor="white"
                  py={7}
                  borderRadius={15}
                  // marginBottom="146px"
                  marginTop="61px"
                  style={{
                    fontWeight: "400",
                    fontSize: 18,
                    lineHeight: 26,
                    color: "#98A2B3",
                  }}
                  onChangeText={(text) => setEmail(text)}
                  InputRightElement={
                    <Button
                      size="md"
                      marginRight={2}
                      borderRadius="10px"
                      backgroundColor={"#5589A8"}
                      w="163px"
                      h="52px"
                      _text={{
                        fontWeight: "400",
                        fontSize: 18,
                        lineHeight: 26,
                      }}
                      onPress={() => {
                        if (email.match(mailformat)) {
                          subscribe({ EMAIL: email })
                        }
                        else {
                          alert('Enter Valid Email')
                        }
                      }}
                    >
                      {"Join"}
                    </Button>
                  }
                  placeholder="Enter your email address"
                />
                <Text
                  fontWeight={"700"}
                  fontSize={"24px"}
                  lineHeight={"40.05px"}
                  color={"#344054"}
                  textAlign="center"
                  // marginTop={"75px"}
                  marginBottom="146px"
                >
                  {message}
                </Text>
              </>
            )
          }
          }
        />

      </VStack>
    </Box>
  );
};
