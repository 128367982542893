import {
  Box,
  Image,
  HStack,
  Text,
  VStack,
  Container,
} from "native-base";
import { blackColor, darkGray } from "../constants/Colors";
import {
  inStudioIcon,
  onlineIcon,
  restorationIcon,
  handBalancingIcon,
  pilatesIcon,
  breathWorkIcon,
  weighsIcon,
  yogaIcon,
  strengthIcon,
  hiitIcon,
  cardioIcon,
  boxingIcon,
  sleepIcon,
  nutritionIcon,
  mmaIcon,
  runningIcon,
} from "../Images/CommonImages";
export const ModalitiesComponent = (props) => {
  const imageData = [
    { name: restorationIcon },
    { name: handBalancingIcon },
    { name: pilatesIcon },
    { name: breathWorkIcon },

    { name: weighsIcon },
    { name: yogaIcon },
    { name: strengthIcon },
    { name: hiitIcon },
    { name: cardioIcon },
    { name: boxingIcon },
    { name: sleepIcon },
    { name: nutritionIcon },
    { name: mmaIcon },
    { name: runningIcon },
  ];
  return (
    <Box flex={1} pl={[3, 0, 0, 0]} alignItems={['center', 'center', 'center', '']} >
      <Box pl={[0, 0, 0, "177px"]} pt={"80px"} alignItems={['flex-start', 'center', 'center', "center"]}>

        <Text color={blackColor} fontSize={"44px"} fontWeight={"700"}>
          {props.title ? props.title : "Teach and Learn"}
        </Text>
        <Text color={darkGray} fontSize={"32px"} fontWeight={"400"}>
          14+ modalities in One Place
        </Text>
        <HStack justifyContent={['center', 'center', 'center', '']} marginTop={[5, 5, 5, "92px"]} flexWrap={"wrap"}>
          {imageData.map((item) => {
            return (
              <Image
                source={{ uri: item.name }}
                width={"160.47px"}
                height={"135.08px"}
                mr={"10px"}
                mb={"10px"}
              />
            );
          })}
        </HStack>
      </Box>
      {
        !props.noGroupClasses && (
          <Container alignItems={['flex-start']} pl={[0, 0, 0, "177px"]} pt={"53px"} pb={"11px"}>
            <Text fontSize={24} fontWeight={"500"}>
              Group Classes
            </Text>
            <HStack pt={"19px"} alignItems={"center"}>
              <Image
                source={{ uri: onlineIcon }}
                width={"40px"}
                height={"40px"}
              />
              <Text pl={"24px"} fontSize={24} fontWeight={"400"}>
                Online
              </Text>
            </HStack>

            <HStack pt={"24px"} alignItems={"center"}>
              <Image
                source={{ uri: inStudioIcon }}
                width={"40px"}
                height={"40px"}
              />
              <Text pl={"24px"} fontSize={24} fontWeight={"400"}>
                In-Studio
              </Text>
            </HStack>
          </Container>
        )
      }
    </Box >
  );
};
