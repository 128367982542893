import {
  Image,
  Text,
  Stack,
  Container,
} from "native-base";
import { whiteColor } from "../constants/Colors";
import {
  takeClassesIcon,
} from "../Images/CommonImages";
export const OnlineClasses = (props) => {
  return (
    <Stack
      flex={1}
      alignItems="center"
      justifyContent="space-evenly"
      direction={["column", "column", "column", "row"]}
      pl={[0, 0, 0, 0]}
      bg={whiteColor}
    >
      <Container
        flex={0.6}
        paddingBottom={[10, 10, 10, "161px"]}
        justifyContent="center"
        alignItems="center"
        paddingTop={"174px"}
      >
        <Text
          lineHeight={"63px"}
          flexWrap={"wrap"}
          fontSize={44}
          fontWeight={"700"}
        >
          Take Online or In-Person Classes from Instructors Worldwide
        </Text>
        <Text pt={"16px"} flexWrap={"wrap"} fontSize={20} fontWeight={"400"}>
          Find the Best Instructors for yourself with advanced search filters
        </Text>
      </Container>

      <Image
        source={{ uri: takeClassesIcon }}
        width={"667px"}
        height={["280.49px", "280.49px", "280.49px", "350.49px"]}
        resizeMode={"contain"}
        alignSelf={"flex-end"}
      />
    </Stack>
  );
};
