import * as React from "react"

const InstagramSvg = (props) => (
  <svg
    width={14}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.189 2.071c1.808 0 2.023.007 2.737.04.43.005.855.084 1.258.233A2.244 2.244 0 0 1 12.47 3.63c.15.403.228.828.233 1.258.033.714.04.929.04 2.737 0 1.809-.007 2.023-.04 2.738-.005.43-.084.855-.233 1.258a2.245 2.245 0 0 1-1.286 1.286c-.403.149-.828.228-1.258.233-.714.032-.928.04-2.737.04-1.81 0-2.023-.008-2.738-.04a3.759 3.759 0 0 1-1.258-.233 2.244 2.244 0 0 1-1.286-1.286 3.758 3.758 0 0 1-.233-1.258c-.032-.715-.04-.929-.04-2.738 0-1.808.008-2.023.04-2.737.005-.43.084-.855.233-1.258a2.243 2.243 0 0 1 1.286-1.286c.403-.149.829-.228 1.258-.233.715-.032.929-.04 2.738-.04Zm0-1.22c-1.839 0-2.07.008-2.793.04a4.974 4.974 0 0 0-1.645.315c-.452.17-.861.437-1.2.782a3.318 3.318 0 0 0-.782 1.2 4.974 4.974 0 0 0-.314 1.645c-.033.722-.04.953-.04 2.792s.007 2.07.04 2.793c.011.562.118 1.118.315 1.645.17.452.437.861.782 1.2a3.32 3.32 0 0 0 1.2.781 4.974 4.974 0 0 0 1.644.315c.723.033.954.04 2.793.04 1.84 0 2.07-.007 2.793-.04a4.975 4.975 0 0 0 1.646-.315 3.468 3.468 0 0 0 1.98-1.982 4.971 4.971 0 0 0 .315-1.645c.032-.722.04-.953.04-2.792s-.008-2.07-.04-2.793a4.974 4.974 0 0 0-.316-1.645 3.319 3.319 0 0 0-.781-1.2 3.318 3.318 0 0 0-1.2-.781A4.974 4.974 0 0 0 9.981.89C9.259.859 9.027.85 7.189.85Z"
      fill="#fff"
    />
    <path
      d="M7.19 4.148a3.479 3.479 0 1 0 0 6.957 3.479 3.479 0 0 0 0-6.957Zm0 5.736a2.258 2.258 0 1 1 0-4.516 2.258 2.258 0 0 1 0 4.516ZM10.805 4.822a.813.813 0 1 0 0-1.625.813.813 0 0 0 0 1.625Z"
      fill="#fff"
    />
  </svg>
)

export default InstagramSvg
