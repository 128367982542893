import {
  Box,
  Image,
  Text,
  VStack,
  Stack,
} from "native-base";
import { ListComponent } from "../atoms/ListComponent";
import { lightYellow } from "../constants/Colors";
import {
  createGroupIcon,
  locateEventIcon,
  benefitRightImage,
  marketPlaceIcon,
  searchYellowIcon,
  reviewsIcon,
  calenderIcon,
} from "../Images/CommonImages";
export const Benefits = (props) => {
  return (
    <Box width={"100%"}
      //  alignItems={'center'}
      pt={[10, 10, 10, '123px']} pb={[0, 0, 0, 20]}
      bg={"#f8faf9"}

    >
      <Text
        flexWrap={"wrap"}
        alignSelf="center"
        marginBottom={[10, 10, 10, "81px"]}
        fontSize={44}
        fontWeight={"700"}
      >
        Benefits
      </Text>
      <Stack
        direction={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        alignItems={['center', 'center', 'center', "center"]}


      >
        <VStack
          mx={[5, 5, 5, 20]}
          flex={0.9}

          alignItems="center"
        //  marginLeft={"24"}
        >
          <ListComponent
            image={marketPlaceIcon}
            title="Be part of an international marketplace"
            description={
              "Your community is paramount, but it doesn’t need to be limited to your physical location. Allow your offerings to be found and enjoyed on a global scale."
            }
          />
          <ListComponent
            image={createGroupIcon}
            title="Invite instructors to join your profile"
            description={
              "Share feeds and calendars once you are connected. Permit schedule changes to update automatically."
            }
            bg={lightYellow}
            mt={"24px"}
          />
        </VStack>
        <Image
          //  flex={1}
          source={{ uri: benefitRightImage }}
          width={["295.53px", "295.53px", "295.53px", "634px"]}
          height={["295px", "295px", "295px", "395px"]}
          resizeMode="contain"
          marginTop={[10, 10, 10, 0]}
        />
      </Stack>
      <Stack


        direction={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        alignItems="center"
        mt={[0, 0, 0, "1/6"]}
        flex={1}


      >
        <Image
          source={{ uri: locateEventIcon }}
          width={["", "395.53px", "395.53px", ""]}
          height={['', '', '', "lg"]}
          resizeMode="contain"
          marginTop={[0, 10, 10, 0]}
          flex={1}

        />
        <VStack flex={1} marginX={[5, 5, 5, "24"]} >
          <ListComponent
            image={calenderIcon}
            title="Create meet-ups, promos, and events"
            description={
              "Easy and simple promotional options integrated into your feed and accessible across the platform."
            }
            bg="#F5F3EF"
          />
          <ListComponent
            image={reviewsIcon}
            title="Create meet-ups, promos, and events"
            description={
              "Easy and simple promotional options integrated into your feed and accessible across the platform."
            }
            mt={"24px"}
            bg="#F0F3EE"
          />
          <ListComponent
            image={searchYellowIcon}
            title="Join cross-platform search results"
            description={
              "Emerge from the constraints of an isolating SaaS. Open your doors to two-way discovery."
            }
            mt={"24px"}
            bg={"#FFFAEB"}
          />
        </VStack>
      </Stack>
    </Box>
  );
};
