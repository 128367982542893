import {
  Image,
  Text,
  Stack,
  Container,
} from "native-base";
import { blackColor } from "../constants/Colors";
export const LiveVideoComponent = (props) => {
  return (
    <Stack
      width={"100%"}
      direction={["column", "column", "column", "row"]}
      padding={"30px"}
      justifyContent="space-evenly"
      alignItems={"center"}
      pt={"80px"}
    >
      <Image
        source={{
          uri: props.image,
        }}
        width={"486.76px"}
        height={"262.9px"}
        resizeMode="contain"
      />
      <Container
        flex={0.6}
        pt={[10, 10, 10, 0]}
        alignItems={"center"}
        marginLeft={[0, 0, 0, "154px"]}
      >
        <Text
          lineHeight={50}
          flexWrap="wrap"
          color={blackColor}
          fontSize={44}
          fontWeight={"700"}
        >
          {props.headerText}
        </Text>
        <Text pt={"16px"} color={blackColor} fontSize={20} fontWeight={"400"}>
          {props.simpleText}
        </Text>
      </Container>
    </Stack>
  );
};
