import {
  HStack,
  Pressable,
  Text,
  VStack,
  FlatList,
  AddIcon,
  MinusIcon,
  Center,
  Divider,
} from "native-base";
import { useState } from "react";
import { blackColor, lightGray, whiteColor } from "../constants/Colors";
export const Faq = (props) => {
  const [data, setData] = useState([
    {
      title: "What platform is deepmove available on?",
      description:
        "deepmove is available to download free for iOS, Android and Web.",
    },
    {
      title: "How much does deepmove charge for listing my course on the app?",
      description:
        "Listing your classes and using the app is free. If students want to book classes through us, our fees are a 5% + $1 per transaction. This helps cover credit card processing fees (which are 2.9% + .30 cents per transaction).",
    },
    {
      title: "What types of instructors are you looking for?",
      description:
        "deepmove does not exclude! If you teach movement, health, and wellness, we would love to have you as part of our community.",
    },
    {
      title: "How does deepmove help me expand my clientele?",
      description:
        "We are building a network-based marketplace. Where there is more content, there are more buyers. Students will have the ability to browse rather than perform blind searches, and when they arrive, they have the option to bring their friends, or, your potential clients.",
    },
    {
      title: "How are my lessons made available to students?",
      description:
        "On deepmove, students can access your classes via livestream and on-demand services. They can even book in-person sessions.",
    },
  ]);

  const updateLayout = (item, index) => {
    const array = [...data];
    array[index]["isExpanded"] = !array[index]["isExpanded"];
    setData(array);
  };
  return (
    <Center bg={whiteColor} paddingBottom={"120px"} paddingTop={"120px"}>
      <Text paddingBottom={"104px"} fontSize={42} fontWeight={"700"}>
        FAQ's
      </Text>
      <HStack
        backgroundColor={
          props.backGroundColor ? props.backGroundColor : whiteColor
        }
        width="70%"
        borderRadius={10}
        borderColor={lightGray}
        borderWidth={1}
      >
        <FlatList
          data={data}
          renderItem={({ item, index }) => (
            <>
              <VStack flex={1} marginY={5} paddingX={10}>
                <Pressable
                  display="flex"
                  justifyContent={"space-between"}
                  flexDirection="row"
                  onPress={() => {
                    updateLayout(item, index);
                  }}
                  justifyItems="center"
                  alignItems={"center"}
                >
                  <Text
                    fontSize={20}
                    fontWeight={500}
                    color={"#344054"}
                    lineHeight={30}
                  >
                    {item.title}
                  </Text>
                  {item?.isExpanded ? (
                    <MinusIcon size="sm" color={blackColor} />
                  ) : (
                    <AddIcon size="sm" color={blackColor} />
                  )}
                </Pressable>
                {item?.isExpanded && (
                  <Text
                    marginTop={14}
                    fontSize={18}
                    fontWeight={"400"}
                    color={"#667085"}
                    lineHeight={30}
                  >
                    {item.description}
                  </Text>
                )}
              </VStack>
              <Divider marginRight={"40%"} backgroundColor={lightGray} />
            </>
          )}
          keyExtractor={(item) => item.id}
        />
      </HStack>
    </Center>
  );
};
