const isProd = false;
const TEST_VALUES = {

  BASE_SERVER_URL:  'http://localhost:8080/api',
  SERVER_URL:  'http://localhost:8080/',
  PLAY_STORE_URL:  'https://play.google.com/store/apps/details?id=com.deepmove.deepmove2',
  APPSTORE_URL: 'https://apps.apple.com/us/app/deepmove/id1591648840',
  LOGIN_URL: 'https://web2.deepmove.com/',
};
const LIVE_VALUES = {

  BASE_SERVER_URL: isProd ? 'https://app2.deepmove.com/api' : 'https://dev.deepmove.com/api',
  SERVER_URL: isProd ? 'https://app2.deepmove.com/' : "https://dev.deepmove.com/",
  PLAY_STORE_URL:  'https://play.google.com/store/apps/details?id=com.deepmove.deepmove2',
  APPSTORE_URL: 'https://apps.apple.com/us/app/deepmove/id1591648840',
  LOGIN_URL: 'https://web2.deepmove.com/',
};

class Constants {
  constructor() {
    this.isProd = isProd;
    this.VALUES = TEST_VALUES // LIVE_VALUES : TEST_VALUES;
    //this.VALUES = TEST_VALUES;
    this.testMode = false;
  }



  setTestMode = (test) => {
    this.testMode = test;
    this.VALUES = test ? TEST_VALUES : LIVE_VALUES;
  }
}
export default new Constants();
