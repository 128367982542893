import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Students from './pages/Students';
import Teachers from './pages/Teachers';
import Studios from './pages/Studios';
import Contact from './pages/Contact';
import TermsAndConditions from './pages/TermsAndConditions';
import { ProSidebarProvider } from 'react-pro-sidebar';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDnq6AJuy3HpKaFTsLLedY8tmx2CU-otPc",
  authDomain: "deepmove-app.firebaseapp.com",
  projectId: "deepmove-app",
  storageBucket: "deepmove-app.appspot.com",
  messagingSenderId: "776484487823",
  appId: "1:776484487823:web:4f891aec41148f22e9e6fa",
  measurementId: "G-54MH677LJQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <ProSidebarProvider>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/Students"} element={<Students />} />
          <Route path={"/Teachers"} element={<Teachers />} />
          <Route path={"/Studios"} element={<Studios />} />
          <Route path={"/Contact"} element={<Contact />} />
          <Route path={"/terms-and-conditions-2"} element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </ProSidebarProvider>
  );
}

export default App;
