import {
  Box,
  HamburgerIcon,

  NativeBaseProvider,
  Pressable,
  ScrollView,
} from "native-base";
import { Download } from "../components/Download";
import { Faq } from "../components/Faq";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ModalitiesComponent } from "../components/ModalitiesComponent";
import {
  landingIcon,
} from "../Images/CommonImages";
import { DemandVideo } from "../components/DemandVideo";
import { EarningStepComponent } from "../components/EarningStepComponent";
import { OnlineClasses } from "../components/OnlineClasses";
import { DashBoardComponent } from "../components/DashBoardComponent";
import { PeopleReviewComponent } from "../components/PeopleReviewComponent";
import { whiteColor } from "../constants/Colors";

const Home = () => {

  return (
    <NativeBaseProvider>
      <ScrollView bg={whiteColor}>

        <Box bg={whiteColor} flex={1}>
          <Header
            headerText={"Finally, one platform does it all."}
            simpleText={"Train, connect, teach, learn. One login, all possibilities."}
            iconImage={landingIcon}
            backGroundColor={"#f3f9fd"}
            LandingScreen
          />

          <ModalitiesComponent />
          <DemandVideo />
          <EarningStepComponent />
          <OnlineClasses />
          <DashBoardComponent />
          <PeopleReviewComponent />
          <Faq />
          <Download />
          <Footer />
        </Box>
      </ScrollView>
    </NativeBaseProvider>
  );
};

export default Home;
