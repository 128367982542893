import * as React from "react"

const MailSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.82 6.395c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2m20 0v12c0 1.1-.9 2-2 2h-16c-1.1 0-2-.9-2-2v-12m20 0-10 7-10-7"
      stroke="#524F51"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MailSvg
