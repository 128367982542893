import {
  ScrollView,
  Box,
  NativeBaseProvider,
  Stack,
  Container,
} from "native-base";
import { CreateClassComponent } from "../components/CreateClassComponent";
import { Download } from "../components/Download";
import { Faq } from "../components/Faq";
import { FeedBackComponent } from "../components/FeedBackComponent";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { LiveVideoComponent } from "../components/LiveVideoComponent";
import { ManageAppComponent } from "../components/ManageAppComponent";
import { ModalitiesComponent } from "../components/ModalitiesComponent";
import { RobustRoaster } from "../components/RobustRoaster";
import { TeamComponent } from "../components/TeamComponent";
import { lightWhite } from "../constants/Colors";
import { paymentIcon, videoIcon } from "../Images/CommonImages";

const Teachers = () => {
  return (
    <NativeBaseProvider>
      <ScrollView bg={lightWhite}>
        <Box bg={lightWhite} flex={1}>
          <Header
            headerText={'Your organizing platform is here.'}
            simpleText={'Your community, your communications, your calendar. No more separate accounts for every facet of your teaching. Let deepmove simplify operations so you can focus on what you do best.'}
            backGroundColor={lightWhite} />
          <Container marginTop={[10, 10, 10, '144px']} />
          <ModalitiesComponent noGroupClasses />
          <Stack marginY={"170px"}>
            <LiveVideoComponent
              image={videoIcon}
              headerText={"Integrated live-streaming and on-demand videos"}
              simpleText={
                "No need for additional live-streaming accounts. Do what you need here, in one place."
              }
            />
          </Stack>
          <RobustRoaster
            title={"Robust rosters with class histories"}
            description={"Keep track of attendance and class histories."}
          />
          <LiveVideoComponent
            image={paymentIcon}
            headerText={"Seamless payment for your clients"}
            simpleText={
              "Easy double-click payments via Stripe, Apple, and Google, our trusted payment partners. Transparent payouts to you."
            }
          />
          <FeedBackComponent />
          <TeamComponent />
          <ManageAppComponent />
          <CreateClassComponent />
          <Faq />
          <Download backgroundColor={"#f3efe9"} />
          <Footer />
        </Box>
      </ScrollView>
    </NativeBaseProvider>
  );
};

export default Teachers;
