import {
  Box,
  Image,
  Text,
  VStack,
  Stack,
} from "native-base";
import { whiteColor } from "../constants/Colors";
import {
  teamIcon,
} from "../Images/CommonImages";
export const TeamComponent = (props) => {
  return (
    <Box width={"100%"} pl={[0, 0, 0, "153px"]} bg={whiteColor}>
      <Stack justifyContent={"space-evenly"} direction={["column", "column", "column", "row"]}>
        <VStack alignItems={"center"}>
          <Image
            source={{ uri: teamIcon }}
            width={"395.53px"}
            height={"380.07px"}
          />
        </VStack>
        <VStack
          flex={0.5}
          padding={[20]}
          alignItems={"flex-start"}
          justifyContent={"center"}
        >
          <Text lineHeight={40} fontWeight={"700"} fontSize={"44px"}>
            Build team verticals
          </Text>
          <Text paddingTop={"16px"} fontWeight={"400"} fontSize={20}>
            Feature your teachers and those who have impacted your total health
            in order to share best practices with others.
          </Text>
        </VStack>
      </Stack>
    </Box>
  );
};
