import * as React from "react"

const FacebookSvg = (props) => (
  <svg
    width={7}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.96 12.713V7.159H.093V4.995H1.96V3.399C1.96 1.546 3.092.538 4.744.538c.792 0 1.472.059 1.67.085V2.56H5.268c-.898 0-1.072.427-1.072 1.054v1.382h2.143l-.28 2.164H4.197v5.554H1.96Z"
      fill="#fff"
    />
  </svg>
)

export default FacebookSvg
