import { Image, HStack, Text, VStack, Center, Stack } from "native-base";
export const ListComponent = ({ image, title, description, bg, mt }) => {
  return (
    <HStack
      bg={bg ? bg : "#eef4f7"}
      p={"20px"}
      borderRadius={"16px"}
      mt={mt ? mt : "0"}
    >
      <Stack alignItems={'center'} justifyContent={['', '', '', 'center']} flex={[0.3, 0.3, 0.3, 0.2]}>
        <Image source={image} width={"56px"} height={"56px"} />
      </Stack>
      <VStack flex={0.7}>
        <Text fontWeight={"500"} fontSize={"24px"} lineHeight={"31px"}>{title}</Text>
        <Text color={"#667085"} fontWeight={"400"} fontSize={"20px"} lineHeight={"26px"}>
          {description}
        </Text>
      </VStack>
    </HStack>
  );
};
