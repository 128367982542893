import {
  Box,
  Image,
  Text,
  VStack,
  Stack,
} from "native-base";
import { feedbackIcon } from "../Images/CommonImages";
export const FeedBackComponent = (props) => {
  return (
    <Stack
      flexDirection={["column", "column", "column", "row"]}
      width={"100%"}
      pr={[0, 0, 0, "154px"]}
      justifyContent={"space-evenly"}
      alignItems={"center"}
    >
      <VStack flex={0.4} padding={[10, 10, 10, 20]} justifyContent={"center"}>
        <Box>
          <Text lineHeight={50} fontSize={44} fontWeight={"700"}>
            {props.headerText
              ? props.headerText
              : "Collect feedback and reviews"}
          </Text>
          <Text pt={"22px"} fontSize={20} fontWeight={"400"}>
            {props.simpleText
              ? props.simpleText
              : "Know what your strengths are and grow towards them."}
          </Text>
        </Box>
      </VStack>
      <VStack
        flex={0.7}
        alignItems={["center", "center", "center", "flex-end"]}
      >
        <Image
          source={{
            uri: props.Image ? props.Image : feedbackIcon,
          }}
          width={"381.1px"}
          height={"615.34px"}
          resizeMode="contain"
        />
      </VStack>
    </Stack>
  );
};
