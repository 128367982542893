import {
  Box,
  Image,
  Pressable,
  Text,
  VStack,
  Stack,
} from "native-base";
import { lightBrown, whiteColor } from "../constants/Colors";
import {
  profileImageOneIcon,
  profileImageTwoIcon,
  profileImageThreeIcon,
} from "../Images/CommonImages";
export const PeopleReviewComponent = (props) => {
  const data = [
    {
      image: profileImageOneIcon,
      text: "I'm so happy to be part of a larger movement community assembled all in one place. Yay!",
      autherName: "Kiersten",
      autherSeat: "Pilates & Yoga Instructor",
    },
    {
      image: profileImageTwoIcon,
      text: "Deepmove has made it easier for me to find the best instructors in my area and get feedback from them on a personal level. Love it!",
      autherName: "Susan",
      autherSeat: "Dance Student",
    },
    {
      image: profileImageThreeIcon,
      text: "I like knowing my teachers and friends are here with me, and I can reach out to them and get inspired at any time.	",
      autherName: "Naomi",
      autherSeat: "Functional Movement Student",
    },
  ];

  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      direction={["column", "column", "column", "column"]}
      flex={1}
      bg={lightBrown}
    >
      <VStack alignItems={"center"} pt={"120px"}>
        <Text fontSize={42} fontWeight={"700"}>
          What People Think
        </Text>
      </VStack>
      <Stack
        justifyContent={"center"}
        alignItems="center"
        direction={["column", "column", "column", "row"]}
      >
        {data.map((item) => {
          return (
            <VStack
              marginRight={[0, 0, 0, "46px"]}
              marginTop={"104px"}
              borderRadius={"10px"}
              width={"372px"}
              alignItems={"center"}
              bg={whiteColor}
              paddingTop={"47px"}
            >
              <Image
                source={{ uri: item.image }}
                width={"90px"}
                height={"90px"}
                borderRadius={"100px"}
              />
              <Text
                pt={"30px"}
                paddingRight={"34px"}
                paddingLeft={"34px"}
                color={"#12141D"}
                textAlign={"center"}
              >
                “{item.text}”
              </Text>
              <Text pt={"45px"} fontSize={16} fontWeight={"600"}>
                {item.autherName}
              </Text>
              <Text pb={"34px"} color={"#52525B"}>
                {item.autherSeat}
              </Text>
            </VStack>
          );
        })}
      </Stack>
      {/* <Box paddingTop={"104px"} alignItems="center" justifyContent={"center"} />
      <Pressable
        width={"179px"}
        height={"48px"}
        backgroundColor="#5589A8"
        borderRadius={"6px"}
        alignItems={"center"}
        justifyContent={"center"}
        alignSelf={"center"}
      >
        <Text fontSize={"18px"} fontWeight={"500"} color={"#FFFFFF"}>
          Download App
        </Text>
      </Pressable> */}
      <Box paddingTop={"120px"} />
    </Stack>
  );
};
