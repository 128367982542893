import {
  ScrollView,
  Box,
  NativeBaseProvider,
} from "native-base";
import { ConnectClients } from "../components/ConnectClients";
import { Download } from "../components/Download";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ManageAppComponent } from "../components/ManageAppComponent";
import { Benefits } from "../components/Benefits";
import { SubscribeNewsletters } from "../components/SubscribeNewsletters";
const Studios = () => {
  return (
    <NativeBaseProvider>
      <ScrollView bg={"#F5F5F5"}>
        <Box bg={"#F5F5F5"} flex={1}>
          <Header
            headerText={"Modernize your studio."}
            simpleText={'Move from archaic systems behind the desk to a streamlined platform in a global marketplace. Cater to and interact with your clients and your teachers in a familiar format. Let your community shine. We’re just getting started!'}
          />
          <ConnectClients />
          <Benefits />
          <ManageAppComponent />
          <SubscribeNewsletters />
          <Download backgroundColor={"#f3efe9"} />
          <Footer />
        </Box>
      </ScrollView>
    </NativeBaseProvider>
  );
};

export default Studios;
