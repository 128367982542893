import { AspectRatio, Box, Container, Image, Stack, Text } from "native-base";
import {
  robustEvent1,
  robustEvent2,
  robustEvent3,
  robustImage,
} from "../Images/CommonImages";

export const RobustRoaster = (props) => {
  return (
    <Stack
      direction={["column", "column", "column", "column", "row"]}
      justifyContent={"space-between"}
    >
      <Container
        marginLeft={[5, 10, 20, 20, 20]}
        marginTop={20}
        overflow={"hidden"}
        w={["100%", "100%", "100%", "100%", "45%"]}
      >
        <Text
          lineHeight={50}
          flexWrap="wrap"
          color={"#344054"}
          fontSize={44}
          fontWeight={"700"}
        >
          {props.title}
        </Text>
        <Text pt={"16px"} color={"#344054"} fontSize={20} fontWeight={"400"}>
          {props.description}
        </Text>
        <Stack
          direction={["column", "column", "row", "row", "row"]}
          w={"100%"}
          alignItems={[
            "center",
            "center",
            "flex-start",
            "flex-start",
            "flex-start",
          ]}
          space={3}
          marginTop={75}
        >
          <Box width={200}>
            <AspectRatio ratio={16 / 9}>
              <Image source={robustEvent1} alt="Alternate Text" />
            </AspectRatio>
          </Box>

          <Box width={200}>
            <AspectRatio ratio={16 / 9}>
              <Image source={robustEvent2} alt="Alternate Text" />
            </AspectRatio>
          </Box>

          <Box width={200}>
            <AspectRatio ratio={16 / 9}>
              <Image source={robustEvent3} alt="Alternate Text" />
            </AspectRatio>
          </Box>
        </Stack>
      </Container>

      {/* Right Side View */}
      <Box
        marginTop={[10, 10, 20, 20, 10]}
        h={500}
        w={["100%", "100%", "100%", "100%", "50%"]}
      >
        {/* <AspectRatio w={["100%","100%","100%","100%","100%"]} AspectRatio={16 / 9}> */}
        <Image
          h="100%"
          resizeMode={["contain", "cover", "contain", "contain", "cover"]}
          resizeMethod={"resize"}
          source={robustImage}
          alt="Alternate Text"
        />
        {/* </AspectRatio> */}
      </Box>
    </Stack>
  );
};
