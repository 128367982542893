import {
  Box,
  Image,
  VStack,
  Stack,
} from "native-base";
import { ListComponent } from "../atoms/ListComponent";
import {
  lightGreen,
  lightWhite,
  lightYellow,
  skyColor,
} from "../constants/Colors";
import {
  locateEventIcon,
  locateIcon,
  connectIcon,
  playIcon,
} from "../Images/CommonImages";
export const LocateComponent = (props) => {
  return (
    <Box width={"100%"} pt={[0, 0, 0, 20]} pb={[0, 0, 0, 20]} bg={lightWhite}>

      <Stack
        direction={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        alignItems="center"

      >
        <Image
          source={{ uri: locateEventIcon }}
          width={"599.55px"}
          height={"615.34px"}
          resizeMode="contain"
          marginTop={[10, 10, 10, 0]}

        />
        <VStack
          flex={0.9}
          width={["100%", "100%", "100%", "50%"]}
          paddingX={[5, 5, 5, 20]}
          mt={[10, 10, 10, 0]}
        >
          <ListComponent
            image={props.topBarImage ? props.topBarImage : locateIcon}
            title={
              props.topBarHeaderText
                ? props.topBarHeaderText
                : "Locate online and in-person instruction"
            }
            description={
              props.topBarSimpleText
                ? props.topBarSimpleText
                : "Widen your search to every attendance type, whether it be in-person or online."
            }
            bg={props.topBarBgColor ? props.topBarBgColor : skyColor}
          />
          {/* <HStack bg={props.topBarBgColor ? props.topBarBgColor : skyColor} p={'20px'} borderRadius={'16px'}>
                        <Center flex={0.1}>
                            <Image
                                source={{ uri: props.topBarImage ? props.topBarImage : locateIcon }}
                                width={'56px'}
                                height={'56px'}
                                resizeMode="contain"

                            />
                        </Center>
                        <VStack flex={0.9} paddingLeft={"16px"}>
                            <Text fontWeight={'500'}>{props.topBarHeaderText ? props.topBarHeaderText : "Locate online and in-person instruction"}</Text>
                            <Text color={darkGray} fontWeight={'400'}>
                                {props.topBarSimpleText ? props.topBarSimpleText : "Widen your search to every attendance type, whether it be in-person or online."}                            </Text>
                        </VStack>
                    </HStack> */}

          <ListComponent
            image={props.centerBarImage ? props.centerBarImage : connectIcon}
            bg={lightGreen}
            title={
              props.centerBarHeaderText
                ? props.centerBarHeaderText
                : "Connect with your instructors"
            }
            description={
              props.centerBarSimpleText
                ? props.centerBarSimpleText
                : "Extend the in-class experience with and learning potential from your favorite instructors"
            }
            mt={"20px"}
          />
          <ListComponent
            image={props.bottomBarImage ? props.bottomBarImage : playIcon}
            bg={lightYellow}
            title={
              props.bottomBarHeaderText
                ? props.bottomBarHeaderText
                : "On Demand Options"
            }
            description={
              props.bottomBarSimpleText
                ? props.bottomBarSimpleText
                : "Take your favorite recorded classes as often as you like."
            }
            mt={"20px"}
          />
        </VStack>
      </Stack>
    </Box>
  );
};
