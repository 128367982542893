import {
  Box,
  Image,
  VStack,
  Stack,
} from "native-base";
import { ListComponent } from "../atoms/ListComponent";
import {
  lightPink,
  lightWhite,
  lightYellow,
  skyColor,
} from "../constants/Colors";
import {
  makeFriendsIcon,
  messageBlueIcon,
  createGroupIcon,
  searchLocationIcon,
} from "../Images/CommonImages";
export const MakeFriendComponent = (props) => {
  return (
    <Box width={"100%"} pt={[0, 0, 0, 20]} pb={[0, 0, 0, 20]} bg={lightWhite}>
      <Stack
        direction={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        alignItems="center"
      >
        <VStack padding={30}>
          <ListComponent
            image={messageBlueIcon}
            title="Find your friends"
            description={
              "Build a network of like-minded and inspiring wellness buddies."
            }
            bg={skyColor}
          />

          <ListComponent
            image={createGroupIcon}
            title="Create Workout Groups"
            description={
              "Build a network of like-minded and inspiring wellness buddies."
            }
            bg={lightYellow}
            mt={"24px"}
          />

          <ListComponent
            image={searchLocationIcon}
            title="Search by location, modality, instructor, etc."
            description={
              "Rich search features and filters to help you find exactly what you are looking for."
            }
            bg={lightPink}
            mt={"24px"}
          />
        </VStack>
        <Image
          source={{ uri: makeFriendsIcon }}
          width={"395.53px"}
          height={"378.19px"}
          resizeMode="contain"
          marginTop={[10, 10, 10, 0]}
        />
      </Stack>
    </Box>
  );
};
