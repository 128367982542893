import { Box, HStack, Text, Image, VStack, Stack, Container } from "native-base";
import { Link } from "react-router-dom";
import FacebookSvg from "../assests/svg/FacebookSvg";
import FooterPattern from "../assests/svg/FooterPattern";
import InstagramSvg from "../assests/svg/InstagramSvg";
import TwitterSvg from "../assests/svg/TwitterSvg";
import { darkBlue, whiteColor } from "../constants/Colors";
import { deepmoveIcon } from "../Images/CommonImages";
export const Footer = (props) => {
  return (
    <Box
      width={"100%"}
      backgroundColor={darkBlue}
      paddingY={20}
      paddingX={[10, "32"]}
      justifyContent="space-between"
    >
      <Stack
        justifyContent={"space-between"}
        direction={["column", "column", "column", "row"]}
        alignItems={['center', 'center', 'center', '']}
      >
        <Container flex={0.5} >
          <VStack maxWidth={510} justifyContent="center">
            <Image
              source={{ uri: deepmoveIcon }}
              width={"248.51px"}
              height={"40.15px"}
              resizeMode="contain"
            />

            <Text
              fontWeight={"700"}
              fontSize={32}
              lineHeight={46}
              color={whiteColor}
              marginTop={"97.42px"}
            >
              We are prioritizing total wellness for all stakeholders.
            </Text>
            <Text
              fontWeight={"400"}
              fontSize={"20px"}
              lineHeight={"29px"}
              color={whiteColor}
              marginTop={"16px"}
            >
              We are here to support you, no matter where you are in your health and wellness journey. We are here to do good so you can be even better.
            </Text>
          </VStack>
        </Container>
        <FooterPattern />
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems="center"
        marginTop={{ lg: 20 }}
        direction={["column", "column", "column", "row"]}
      >
        <HStack flex={0.5} alignItems="flex-start" justifyContent="flex-start">
          <HStack marginRight={"45px"} alignItems="center">
            <TwitterSvg />
            <Stack marginRight={"30px"} />
            <FacebookSvg />
            <Stack marginRight={"30px"} />
            <InstagramSvg />
          </HStack>

          <Link
            style={{
              fontSize: 16,
              fontWeight: "400",
              lineHeight: "16px",
              color: whiteColor,
              textDecoration: "none",
              textDecorationLine: "underline",
              marginRight: "45px",
            }}
            to="#"
            onClick={(e) => {
              window.location.href = "/privacy-policy.html";
              e.preventDefault();
            }}
          >
            Privacy Policy
          </Link>
          <Link
            style={{
              fontSize: 16,
              fontWeight: "400",
              lineHeight: "16px",
              color: whiteColor,
              textDecoration: "none",
              textDecorationLine: "underline",
            }}
            to="#"
            onClick={(e) => {
              window.location.href = "/terms-and-conditions.html";
              e.preventDefault();
            }}
          >

            Terms & Conditions
          </Link>
        </HStack>
        <Container flex={0.5} justifyContent="flex-end" alignItems="flex-end">
          <Text
            fontWeight={"400"}
            fontSize={"16px"}
            lineHeight={"46px"}
            color={whiteColor}
          >
            © Copyright 2022 - deepmove - All rights reserved
          </Text>
        </Container>
      </Stack>
    </Box>
  );
};
